@use "/src/styles/base/resources" as *;

.device-history-page {
  padding: 32px 32px 80px;
  width: 100%;
  .warning-icon {
    font-size: 14px;
    color: #f8ac1c;
    display: block;
    text-align: right;
    margin-top: -28px;
    margin-right: 13px;
  }
  .status-not-pass-fail > div {
    margin-inline-end: calc(-2em - 8px);
    padding-inline-end: calc(2em + 8px);
  }
  .process-run-history {
    margin-top: 0.5em;
  }

  .process-run-info {
    width: 100%;
  }

  .result-badge {
    padding: 0.25em 0.5em;
    background: $white;
    border: 1px solid;
    border-radius: 999em;
    line-height: normal;

    &.result-badge-pass {
      color: $good1;
      border-color: $good1;
    }

    &.result-badge-fail {
      color: $bad1;
      border-color: $bad1;
    }

    &.result-badge-warning {
      color: $warning1;
      border-color: $warning1;
    }
  }

  .ant-card {
    border-radius: $border-radius;
    box-shadow: $glow-shadow;

    .ant-card-head {
      border-radius: $border-radius-top;
      background: $primary3;
    }

    .ant-card-body {
      padding: 16px;
    }
  }

  .ant-collapse {
    border-radius: $border-radius;
    box-shadow: $glow-shadow;
    border: 0;

    .ant-collapse-item {
      border-radius: $border-radius !important;
      border: 0;

      &.ant-collapse-item-active {
        .ant-collapse-header {
          border-radius: $border-radius-top !important;
        }
      }

      .ant-collapse-header {
        border-radius: $border-radius !important;
        background: $primary3;
        padding: 16px;
        font-size: 16px;
        font-weight: 500;
      }

      .ant-collapse-content {
        border-radius: $border-radius-bottom !important;
      }
    }
  }

  .ant-descriptions {
    &:first-child {
      .ant-descriptions-header {
        margin-top: 0;
      }
    }

    .ant-descriptions-view {
      table {
        table-layout: fixed !important;
      }
    }

    .ant-descriptions-header {
      margin: 1em 0 0.5em 0;
    }

    .ant-descriptions-item-label {
      font-size: 16px;
      font-weight: 500;
    }

    .ant-descriptions-item-content {
      font-size: 16px;
    }
  }

  .ant-table {
    font-size: 16px;

    .main-fail-reason {
      font-weight: 500;

      .priority-value {
        color: #e55e67;
      }
    }
  }

  .ant-timeline-item {
    cursor: pointer;

    &.timeline-item-active {
      font-weight: 600;

      .ant-timeline-item-head {
        border-width: 3px;
      }
    }

    &.timeline-item-pass .ant-timeline-item-head {
      color: $good1;
      border-color: $good1;
    }

    &.timeline-item-fail .ant-timeline-item-head {
      color: $bad1;
      border-color: $bad1;
    }

    &.timeline-item-incomplete .ant-timeline-item-head {
      color: $warning1;
      border-color: $warning1;
    }
    &.timeline-item-summary .ant-timeline-item-head {
      color: $gray-cold7;
      border-color: $gray-cold7;
    }
    .ant-timeline-item-tail {
      border-color: #ccc;
    }
  }
}
